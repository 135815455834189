import { createContext, useContext, useState, useEffect } from 'react';

type GuestData = {
  id: string;
  createdAt: string;
  tasks: any[];
  preferences: {
    theme: string;
    notifications: boolean;
  };
};

type GuestContextType = {
  isGuest: boolean;
  guestData: GuestData | null;
  setIsGuest: (value: boolean) => void;
  convertToAccount: () => void;
  saveGuestData: (data: Partial<GuestData>) => void;
};

const GuestContext = createContext<GuestContextType | undefined>(undefined);

const GUEST_STORAGE_KEY = 'timemywork_guest_data';

export function GuestProvider({ children }: { children: React.ReactNode }) {
  const [isGuest, setIsGuest] = useState(false);
  const [guestData, setGuestData] = useState<GuestData | null>(null);

  // Carregar dados do visitante do localStorage
  useEffect(() => {
    const storedData = localStorage.getItem(GUEST_STORAGE_KEY);
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setGuestData(parsedData);
      setIsGuest(true);
    }
  }, []);

  // Inicializar dados do visitante
  const initializeGuestData = () => {
    const newGuestData: GuestData = {
      id: `guest_${Date.now()}`,
      createdAt: new Date().toISOString(),
      tasks: [],
      preferences: {
        theme: 'system',
        notifications: false,
      },
    };
    setGuestData(newGuestData);
    localStorage.setItem(GUEST_STORAGE_KEY, JSON.stringify(newGuestData));
  };

  // Salvar dados do visitante
  const saveGuestData = (data: Partial<GuestData>) => {
    if (!guestData) return;
    
    const updatedData = {
      ...guestData,
      ...data,
    };
    setGuestData(updatedData);
    localStorage.setItem(GUEST_STORAGE_KEY, JSON.stringify(updatedData));
  };

  // Converter para conta normal
  const convertToAccount = () => {
    localStorage.removeItem(GUEST_STORAGE_KEY);
    setIsGuest(false);
    setGuestData(null);
  };

  // Inicializar dados quando se torna visitante
  useEffect(() => {
    if (isGuest && !guestData) {
      initializeGuestData();
    }
  }, [isGuest]);

  return (
    <GuestContext.Provider 
      value={{ 
        isGuest, 
        guestData, 
        setIsGuest, 
        convertToAccount,
        saveGuestData,
      }}
    >
      {children}
    </GuestContext.Provider>
  );
}

export function useGuest() {
  const context = useContext(GuestContext);
  if (context === undefined) {
    throw new Error('useGuest must be used within a GuestProvider');
  }
  return context;
} 