import { useState } from "react";
import { motion } from "framer-motion";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { PlayCircle, StopCircle, Plus, Clock } from "lucide-react";

const Dashboard = () => {
  const [isTracking, setIsTracking] = useState(false);
  const [title, setTitle] = useState("");
  const [link, setLink] = useState("");
  const [notes, setNotes] = useState("");

  const handleStartTracking = () => {
    setIsTracking(true);
    // TODO: Implement time tracking
    console.log("Started tracking");
  };

  const handleStopTracking = () => {
    setIsTracking(false);
    // TODO: Implement time tracking
    console.log("Stopped tracking");
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-indigo-50 via-white to-purple-50 p-8">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="max-w-4xl mx-auto space-y-8"
      >
        <div className="flex justify-between items-center">
          <h1 className="text-3xl font-bold">Dashboard</h1>
          <Button onClick={() => console.log("Show manual entry modal")}>
            <Clock className="mr-2 h-4 w-4" />
            Add Manually
          </Button>
        </div>

        <Card className="glass-card">
          <CardHeader>
            <CardTitle>New Task</CardTitle>
          </CardHeader>
          <CardContent className="space-y-4">
            <Input
              placeholder="Task title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <Input
              placeholder="Reference link (optional)"
              value={link}
              onChange={(e) => setLink(e.target.value)}
            />
            <Textarea
              placeholder="Notes (optional)"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              className="min-h-[100px]"
            />
            <div className="flex justify-end space-x-4">
              {!isTracking ? (
                <Button onClick={handleStartTracking} className="space-x-2">
                  <PlayCircle className="h-4 w-4" />
                  <span>Start Tracking</span>
                </Button>
              ) : (
                <Button onClick={handleStopTracking} variant="destructive" className="space-x-2">
                  <StopCircle className="h-4 w-4" />
                  <span>Stop Tracking</span>
                </Button>
              )}
            </div>
          </CardContent>
        </Card>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <Card className="glass-card">
            <CardHeader>
              <CardTitle>Recent Tasks</CardTitle>
            </CardHeader>
            <CardContent>
              <p className="text-gray-500 text-center py-8">No tasks yet</p>
            </CardContent>
          </Card>

          <Card className="glass-card">
            <CardHeader>
              <CardTitle>Today's Summary</CardTitle>
            </CardHeader>
            <CardContent>
              <p className="text-gray-500 text-center py-8">No data available</p>
            </CardContent>
          </Card>
        </div>
      </motion.div>
    </div>
  );
};

export default Dashboard;